import { createContext, useContext, useEffect, useState } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useRouter } from 'next/router';
import UserContext from './UserContext';
import { postCheckUser } from "../src/dataProvider";
import { useIdleTimer } from 'react-idle-timer';


const AuthContext = createContext({});
const Keycloak = typeof window !== 'undefined' ? require('keycloak-js') : null;

export const AuthProvider = ({ children }) => {
    const router = new useRouter();
    const [loading, setLoading] = useState(true);
    const [failedAuth, setFailedAuth] = useState(false);
    const [idle, setIdle] = useState(false);
    let { keycloakstate, setKeycloakstate } = useContext(UserContext);

    // set 'idle' after 30 minutes of inactivity
    useIdleTimer({
        timeout: 1000 * 60 * 30,
        onIdle: () => setIdle(true),
        onActive: () => setIdle(false),
        debounce: 500
    });

    // ran on every page change, if not logged in
    const getAuthentication = async () => {
        let keycloakId;
        let email;
        let lastName;
        let firstName;
        let username;
        let gemiNumber;
        let afm;
        let companyName;
        let isAuthenticated = false;

        const keycloak = Keycloak("../../keycloak/" + process.env.keycloak);

        if (pageRequiresAuth()) {
            await keycloak.init({ onLoad: 'login-required' }).then(authenticated => {
                setKeycloakstate({
                    keycloak: keycloak,
                    authenticated: authenticated,
                    token: keycloak.token,
                    userName: keycloak.tokenParsed?.name,
                    user_id: keycloak.tokenParsed?.sub,
                    roles: keycloak.tokenParsed?.realm_access?.roles
                });
                if (authenticated) {
                    keycloakId = keycloak.tokenParsed.sub;
                    email = keycloak.tokenParsed.email;
                    lastName = keycloak.tokenParsed.family_name;
                    firstName = keycloak.tokenParsed.given_name;
                    username = keycloak.tokenParsed.preferred_username;
                    gemiNumber = keycloak.tokenParsed.gemi_number;
                    afm = keycloak.tokenParsed.afm;
                    companyName = keycloak.tokenParsed.co_name;

                    isAuthenticated = true;
                } else {
                    let currentURL = window.location.protocol + "//" + window.location.host + '/unauthorizedAccess';
                    keycloakstate.keycloak.logout({ redirectUri: currentURL });
                }
            }).catch(() => { router.push('/'); });
        } else if (!failedAuth) { // try automatic auth if it hasnt failed before
            await keycloak.init({ onLoad: 'check-sso', checkLoginIframe: true }).then(authenticated => {
                setKeycloakstate({
                    keycloak: keycloak,
                    authenticated: authenticated,
                    token: keycloak.token,
                    userName: keycloak.tokenParsed?.name,
                    user_id: keycloak.tokenParsed?.sub,
                    roles: keycloak.tokenParsed?.realm_access?.roles
                });
                if (authenticated) {
                    keycloakId = keycloak.tokenParsed.sub;
                    email = keycloak.tokenParsed.email;
                    lastName = keycloak.tokenParsed.family_name;
                    firstName = keycloak.tokenParsed.given_name;
                    username = keycloak.tokenParsed.preferred_username;
                    gemiNumber = keycloak.tokenParsed.gemi_number;
                    afm = keycloak.tokenParsed.afm;
                    companyName = keycloak.tokenParsed.co_name;

                    isAuthenticated = true;
                } else {
                    setFailedAuth(true);
                }
            }).catch(() => { router.push('/'); });
        }

        return { keycloakId, email, lastName, firstName, username, gemiNumber, afm, companyName, authenticated: isAuthenticated };
    };

    // boolean: does current page require auth?
    const pageRequiresAuth = () => {
        let authCheck = false;
        const securePages = [
            "/questions/add",
            "/questions/edit/[id]",
            "/management",
            "/parametricFiles",
            "/logIn",
        ];
        if (securePages.includes(router.pathname)) {
            authCheck = true;
        }
        return authCheck;
    };

    const createUserSession = async (userData) => {
        sessionStorage.setItem('userSession', JSON.stringify(userData));
        return true;
    };

    // help function used in effect below
    const updateToken = async () => {
        // dont run any logic if logged out
        if (keycloakstate.authenticated) {
            // if user is not idle, update token
            if (!idle) {
                try {
                    // update the token if it has less than 5 mins life
                    const refreshed = await keycloakstate.keycloak.updateToken(5 * 60);
                    if (refreshed) { // if token is refreshed, update the state
                        setKeycloakstate({
                            ...keycloakstate,
                            token: keycloakstate.keycloak.token,
                            userName: keycloakstate.keycloak.tokenParsed.name,
                            user_id: keycloakstate.keycloak.tokenParsed.sub,
                            roles: keycloakstate.keycloak.tokenParsed.realm_access.roles
                        });
                    }
                } catch (err) {
                    console.error(err);
                }
            } else {// if idle, just log out
                logout();
            }
        }
    };

    // ran when anything goes wrong
    const logout = () => {
        sessionStorage.removeItem('authentication');
        sessionStorage.removeItem('refreshToken');
        sessionStorage.removeItem('userSession');

        let currentURL = window.location.protocol + "//" + window.location.host;
        keycloakstate.keycloak.logout({ redirectUri: currentURL });
    };

    // ran on every page change
    const tryAuth = async () => {
        setLoading(true);

        if (window !== undefined && keycloakstate.authenticated !== true) {
            let { keycloakId, email, lastName, firstName, gemiNumber, authenticated, afm, companyName, username } = await getAuthentication();
            if (authenticated) {
                const userData = await postCheckUser(keycloakId, email, lastName, firstName, gemiNumber, afm, companyName, username);
                await createUserSession(userData);
            }
            setLoading(false);
        } else {
            setLoading(false);
        }
    };

    useEffect(async () => {
        if (router.pathname) tryAuth();
    }, [router.pathname]);

    // try to update keycloak token every minute
    useEffect(() => {
        // if keycloak is loaded, try to update token every minute
        if (keycloakstate?.keycloak?.updateToken && setKeycloakstate) {
            const interval = setInterval(() => updateToken(), 60 * 1000);
            return () => {
                clearInterval(interval);
            };
        }
    }, [keycloakstate, setKeycloakstate, idle]);

    // update keycloak session when keycloak data changes
    useEffect(() => {
        if (keycloakstate?.keycloak && keycloakstate?.token) {
            sessionStorage.setItem('authentication', keycloakstate.token);
            sessionStorage.setItem('refreshToken', keycloakstate.keycloak.refreshToken);
        }
    }, [keycloakstate]);

    // single-log-out handler
    useEffect(() => {
        if (keycloakstate?.keycloak) {
            // check-sso by default does not Single-Sign-Out, so we do it here
            keycloakstate.keycloak.onAuthLogout = logout;
        }
    }, [keycloakstate?.keycloak]);

    return (
        <AuthContext.Provider value={{ loading }}>{children}</AuthContext.Provider>
    );
};

const useAuth = () => useContext(AuthContext);

export const ProtectRoute = ({ children }) => {
    const { loading } = useAuth();
    if (loading) {
        return <LinearProgress />;
    }
    return children;
};